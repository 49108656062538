import React, { useEffect, useRef, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./ProfilePicture.css";
import { useUploadCandidateProfilePictureMutation } from "../../features/candidate/candidateSlice";
import Resizer from "react-image-file-resizer";
import { errorToast, successToast } from "../common/ToastWrapper/ToastWrapper";
import LoadingSpinner from "../common/LoadingSpinner/LoadingSpinner";

export const ProfilePicture = ({ modal, setModal }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imageLoading, setImageLoading] = useState(null);
  const fileRef = useRef(null);

  const [
    uploadCandidateProfilePicture,
    {
      isLoading: uploadCandidateProfilePictureIsLoading,
      isSuccess: uploadCandidateProfilePictureIsSuccess,
      isError: uploadCandidateProfilePictureIsError,
    },
  ] = useUploadCandidateProfilePictureMutation();

  const handleSubmit = async () => {
    toggleModal();
    try {
      if (selectedFile) {
        if (selectedFile?.size > 2 * 1024 * 1024) {
          setImageLoading(true);
          Resizer.imageFileResizer(
            selectedFile,
            400, //width
            400, //height
            "JPEG", // Output format (you can change it to PNG or other formats)
            100, // Quality of the resized image (0 to 100)
            0, // Rotation
            async (resizedImage) => {
              const formData = new FormData();
              formData.append("ProfilePicture", resizedImage);
              const response = await uploadCandidateProfilePicture({
                image: formData,
              }).unwrap();
              successToast("Profile Picture Uploaded");
              setSelectedFile(null);
              window.location.reload();
            },
            "file"
          );
          setImageLoading(false);
        } else {
          const formData = new FormData();
          formData.append("ProfilePicture", selectedFile);
          const response = await uploadCandidateProfilePicture({
            image: formData,
          }).unwrap();
          successToast("Profile Picture Uploaded");
          setSelectedFile(null);
          window.location.reload();
        }
      } else {
        errorToast("Please select an Image.");
        selectedFile(null);
        setImageLoading(false);
        return;
      }
    } catch (error) {
      errorToast(error?.data?.title);
      selectedFile(null);
      setImageLoading(false);
    }
  };

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    if (!modal) {
      setSelectedFile(null);
      setPreview(null);
    }
  }, [modal]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <>
      {(uploadCandidateProfilePictureIsLoading || imageLoading) && (
        <LoadingSpinner />
      )}
      <Modal isOpen={modal} toggle={toggleModal} centered="true">
        <ModalHeader toggle={toggleModal}>Upload Profile Picture</ModalHeader>
        <ModalBody className="text-center">
          <div
            className="profile-pic-uploader"
            onClick={() => document.getElementById("fileInput").click()}
            title="click here to upload profile picture"
          >
            {preview ? (
              <img
                src={preview}
                alt="Profile Preview"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <p className="text-muted mt-5 p-2">click here to upload image</p>
            )}
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
            onClick={() => (fileRef.current = "")}
            id="fileInput"
          />
          <hr />
          <button
            color="primary"
            onClick={handleSubmit}
            disabled={!selectedFile}
            className="my-2 px-4 upload-file-button"
          >
            Upload Profile Picture
          </button>
        </ModalBody>
      </Modal>
    </>
  );
};
