import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
} from "reactstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";

const ConfirmationModal = ({
  type,
  closeModal,
  showModal,
  confirmResubmit,
  title,
}) => {
  return (
    <Modal
      className="confirmation-modal"
      isOpen={showModal}
      toggle={closeModal}
      centered
      size="md"
    >
      <div className="m-2 mb-0 p-2 fs-6 rounded bg-gradiant rounded d-flex justify-content-between align-items-center">
        <h6 className="modal-title" id="title">
          Confirmation Alert
        </h6>
        <button
          type="button"
          className="btn"
          aria-label="Close"
          onClick={closeModal}
        >
          <img src={closeIcon} style={{ width: "70px" }} alt="" />
        </button>
      </div>

      <ModalBody>
        <h5 style={{ color: "#000" }}>{title}</h5>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-gray" onClick={closeModal}>
          Cancel
        </Button>
        <Button className="btn btn-danger" onClick={confirmResubmit}>
          Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
