import React, { useState, memo } from "react";
import "./CreateProfile.css";
import { TagsInput } from "react-tag-input-component";
import profilPic from "../../assets/images/profile-picture.jpg";
import { useNavigate } from "react-router-dom";
import {
  useRegisterMutation,
  useCreateProfileMutation,
  useUpdateProfileMutation,
  useUpdateUserMutation,
  useGetCandidateDetailsQuery,
} from "../../features/auth/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import ToastWrapper, {
  successToast,
  errorToast,
} from "../../components/common/ToastWrapper/ToastWrapper";
import {
  selectCurrentCandidate,
  selectCurrentUser,
  setCandidate,
  setCredentials,
  setUser,
} from "../../features/auth/authSlice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { useEffect } from "react";
import DotSpinner from "../../components/common/LoadingSpinner/DotSpinner";
import {
  useGetAttachmentsQuery,
  useRemoveCandidateAttachmentMutation,
} from "../../features/candidate/candidateSlice";
import CandidateSkill from "../../components/CandidateSkill/CandidateSkill";
import FileUploadModal from "../../components/FileUploadModal/FileUploadModal";
import { ReactComponent as ViewIcon } from "../../assets/icons/view.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleteIcon.svg";
import axios from "axios";
import { Label, Input, FormGroup } from "reactstrap";
import { ProfilePicture } from "../../components/ProfilePicture/ProfilePicture";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import { getCandidateProfilePictureUrl } from "../../utils/methods";
import CandidateExperienceModal from "../../components/CandidateExperienceModal/CandidateExperienceModal";
import updateIcon from "../../assets/icons/edit.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
import ConfirmationModal from "../../components/common/ConfirmationModal/ConfirmationModal";
import { useRemoveCandidateExperienceMutation } from "../../features/candidate/candidateSlice";
import { useLocation } from "react-router-dom";

const CreateProfile = ({ update }) => {
  const [skills, setSkills] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [mobile, setMobile] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [isConditionsAccepted, setisConditionsAccepted] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [candidateAttachments, setcandidateAttachments] = useState([]);
  const [currentCandidate, setCurrentCandidate] = useState("");
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const [isUploadProfilePicture, setIsUploadProfilePicture] = useState(false);
  const [showCandidateExperienceModal, setShowCandidateExperienceModal] =
    useState(false);
  const [selectedExperience, setSelectedExperience] = useState("");
  const [selectedExperienceGuid, setSelectedExperienceGuid] = useState("");
  const [experienceUpdateMode, setExperienceUpdateMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const location = useLocation();

  const [
    register,
    { isLoading: registerIsLoading, isSuccess: registerIsSuccess },
  ] = useRegisterMutation();

  const [
    removeCandidateExperience,
    {
      isLoading: removeCandidateExperienceIsLoading,
      removeCandidateExperienceIsSuccess,
    },
  ] = useRemoveCandidateExperienceMutation();

  const [
    createProfile,
    { isLoading: createProfileIsLoading, createProfileIsSuccess },
  ] = useCreateProfileMutation();

  const [
    removeCandidateAttachment,
    {
      isLoading: removeCandidateAttachmentIsLoading,
      removeCandidateAttachmentIsSuccess,
    },
  ] = useRemoveCandidateAttachmentMutation();

  const [
    updateUser,
    {
      isLoading: isUpdateUserLoading,
      isSuccess: isUpdateUserSuccess,
      isError: isUpdateUserError,
      error: updateUserError,
    },
  ] = useUpdateUserMutation();

  const {
    data: currentCandidateDetails,
    isLoading: currentCandidateDetailsIsLoading,
    isFetching: currentCandidateDetailsIsFetching,
    error: currentCandidateDetailsError,
    isSuccess: currentCandidateDetailsSuccess,
    refetch: refetchCurrentCandidate,
  } = useGetCandidateDetailsQuery();

  const {
    data: candidateAttachmentsData,
    isLoading: candidateAttachmentsIsLoading,
    isFetching: candidateAttachmentsIsFetching,
    error: candidateAttachmentsError,
    refetch: refetchCandidateAttachments,
  } = useGetAttachmentsQuery();

  const [
    updateProfile,
    {
      isLoading: isUpdateProfileLoading,
      isSuccess: isUpdateProfileSuccess,
      isError: isUpdateProfileError,
      error: updateProfileError,
      reset: updateProfileReset,
    },
  ] = useUpdateProfileMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const candidate = useSelector(selectCurrentCandidate);
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    if (update) {
      setEmail(candidate?.email);
      setFirstName(candidate?.firstName);
      setLastName(candidate?.lastName);
      setMobile(candidate?.mobile);
      setAddressLine1(candidate?.address);
    }
  }, [update, candidate]);

  useEffect(() => {
    if (update && currentCandidate) {
      setSelectedSkills(
        currentCandidate?.skills?.map((skill) => {
          return {
            guid: skill?.guid,
            name: skill?.name,
          };
        })
      );
    } else {
      setSelectedSkills([]);
    }
  }, [currentCandidate, update]);

  useEffect(() => {
    if (!location.pathname.includes("/update-profile")) {
      setSelectedSkills([]);
      setCurrentCandidate("");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (currentCandidateDetails) {
      setCurrentCandidate(currentCandidateDetails);
      dispatch(setCandidate({ ...currentCandidateDetails }));
    }
    if (currentCandidateDetailsError) {
      return;
    }
  }, [currentCandidateDetails, currentCandidateDetailsError]);

  useEffect(() => {
    if (candidateAttachmentsData) {
      setcandidateAttachments(candidateAttachmentsData);
    }
    if (candidateAttachmentsError) {
      return;
    }
  }, [candidateAttachmentsData, candidateAttachmentsError]);

  useEffect(() => {
    if (isUpdateProfileSuccess) {
      refetchCurrentCandidate();
    }
    if (isUpdateProfileSuccess && currentCandidateDetailsSuccess) {
      dispatch(setCandidate(currentCandidate));
      updateProfileReset();
    }
  }, [currentCandidateDetailsSuccess, currentCandidate, updateProfileReset]);

  useEffect(() => {
    if (registerIsSuccess) {
      const navigationTimeout = setTimeout(() => {
        navigate("/find-jobs");
      }, 3500);
      return () => {
        clearTimeout(navigationTimeout);
      };
    }
  }, [registerIsSuccess]);

  const handleAttachmentView = (attachmentGuid) => {
    setAttachmentUrl(null);
    setIsFileDownloading(true);
    if (user?.token) {
      const axiosConfig = {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };

      axios
        .get(
          `${
            window.location.hostname === "localhost"
              ? process.env.REACT_APP_API_URL
              : process.env.PUBLIC_URL
          }/v1/attachments/${attachmentGuid}`,
          axiosConfig
        )
        .then((response) => {
          setIsFileDownloading(false);

          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          setAttachmentUrl(url);
          window.open(url);
        })
        .catch((error) => {
          setIsFileDownloading(false); // Reset loading state on error
          errorToast(error.message);
        });
    }
  };

  const removeCandidateAttachmentOnClick = async (attachmentGuid) => {
    try {
      const response = await removeCandidateAttachment(attachmentGuid).unwrap();
      if (response?.error) {
        errorToast(response?.error?.data?.title || "Something Went wrong");
        return;
      }
      successToast("Attachment Removed Successfully..!");
      refetchCandidateAttachments();
    } catch (error) {
      errorToast(error?.data?.Message);
    }
  };

  const handleProfileCreation = async (e) => {
    e.preventDefault();

    if (!update) {
      if (password !== passwordAgain) {
        errorToast("Passwords doesn't match. Please check again...!");
        return;
      }

      const canSave = [
        email,
        firstName,
        lastName,
        mobile,
        addressLine1,
        password,
        passwordAgain,
      ].every(Boolean);
      if (!canSave) {
        errorToast("Please Fill all the required fields...!");
        return;
      }
      try {
        const candidateData = await register({
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          mobile: mobile,
          address: addressLine1,
          companyId: null,
        }).unwrap();
        if (candidateData) {
          dispatch(setCredentials(candidateData));
        } else {
          errorToast("Something Went wrong while creating your profile...!");
          return;
        }
        successToast("Profile Created Successfully..!");
        clearAll();
      } catch (err) {
        errorToast(err?.data?.Message);
      }
    } else {
      try {
        const candidateResponse = await updateProfile({
          firstName: firstName,
          lastName: lastName,
          mobile: mobile,
          address: addressLine1,
          companyGuid: null,
        }).unwrap();
        if (candidateResponse?.error) {
          errorToast(candidateResponse?.Message || "Something Went wrong");
          return;
        }
        refetchCandidateAttachments();
        successToast("Profile Updated Successfully..!");
        clearAll();
      } catch (error) {
        errorToast("Something Went Wrong...!");
      }
    }
  };

  const removeCandidateExperienceOnClick = async () => {
    try {
      const response = await removeCandidateExperience({
        guid: selectedExperienceGuid,
      }).unwrap();
      if (response?.error) {
        errorToast(response?.error?.data?.title || "Something Went wrong");
        return;
      }
      successToast("Experience Removed Successfully..!");
      setShowDeleteModal(false);
      setSelectedExperienceGuid("");
    } catch (error) {
      errorToast(error?.data?.Message);
    }
  };

  const clearAll = () => {
    setSkills([]);
    setFirstName("");
    setLastName("");
    setPassword("");
    setPasswordAgain("");
    setMobile("");
    setAddressLine1("");
    setisConditionsAccepted(false);
  };

  const cancelAndGoBack = () => {
    navigate("/find-jobs");
  };

  const toggleModal = () => {
    setFileUploadModal(!fileUploadModal);
  };

  return (
    <>
      <div>
        <ToastWrapper />
        <div className="hero-section"></div>

        <div className="container-fluid">
          <div className="row justify-content-center content-container">
            <div className="col-12 ">
              <div className="card create-profile-card">
                {(registerIsLoading ||
                  createProfileIsLoading ||
                  isUpdateUserLoading ||
                  isFileDownloading ||
                  candidateAttachmentsIsLoading ||
                  currentCandidateDetailsIsLoading ||
                  // currentCandidateDetailsIsFetching ||
                  isUpdateProfileLoading) && <LoadingSpinner />}
                <div className="card-header px-5">
                  <span className="fs-4 fw-bold">
                    {update ? "Update" : "Create"} your Profile |
                  </span>
                  <small className="fw-bold px-1">
                    Please {update ? "make your amendments" : "fill"} in the
                    form below
                  </small>
                </div>
                <div className="card-body px-5">
                  <form
                    className="needs-validation"
                    onSubmit={handleProfileCreation}
                  >
                    <div className="row g-3">
                      <div className="image-wrapper">
                        <div className="d-flex justify-content-end align-items-center">
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              backgroundColor: "#f0f0f0",
                            }}
                          >
                            <img
                              src={
                                user?.guid
                                  ? getCandidateProfilePictureUrl(user?.guid)
                                  : profilPic
                              }
                              title="Profile picture"
                              alt="candidate-profile"
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          <>
                            {user ? (
                              <>
                                <EditIcon
                                  onClick={() =>
                                    setIsUploadProfilePicture(true)
                                  }
                                  className="mt-5 edit-profile-pic-icon"
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        </div>
                        <div className="candidate-name mt-2">
                          <p className="text-end m-0">
                            <b>
                              {user ? user?.firstName : ""}{" "}
                              {user ? user?.lastName : ""}
                            </b>
                          </p>
                        </div>
                      </div>
                      <h5 className="">Basic Information</h5>
                      <hr className="my-1 mb-2" />
                      <div className="col-sm-6">
                        <label htmlFor="firstName" className="form-label">
                          First name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          placeholder="first name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="lastName" className="form-label">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastName"
                          placeholder="last name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-12 col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="you@example.com"
                          required
                          readOnly={update}
                          disabled={update}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <label htmlFor="mobile" className="form-label">
                          Mobile
                        </label>
                        <div className="input-group has-validation">
                          <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            placeholder="+9477xxxxxxx"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      {!update ? (
                        <>
                          <div className="col-12 col-md-6">
                            <label htmlFor="password" className="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              placeholder="password"
                              className="form-control"
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label
                              htmlFor="passwordAgain"
                              className="form-label"
                            >
                              Confirm Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="passwordAgain"
                              placeholder="confirm-password"
                              value={passwordAgain}
                              onChange={(e) => setPasswordAgain(e.target.value)}
                              required
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="col-12 col-md-6">
                        <label htmlFor="address" className="form-label">
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-control mb-3"
                          id="address"
                          placeholder="1234 Main St"
                          value={addressLine1}
                          onChange={(e) => setAddressLine1(e.target.value)}
                          required
                        />
                      </div>
                      <ProfilePicture
                        modal={isUploadProfilePicture}
                        setModal={setIsUploadProfilePicture}
                      />
                    </div>

                    <>
                      {update ? (
                        <>
                          <h5 className="mt-4">Professional Details</h5>
                          <hr className="my-1 mb-3" />

                          <div className="row gy-3">
                            <CandidateSkill
                              isUpdate={update}
                              initialSelectedSkills={selectedSkills}
                            />
                            <div>
                              <div className="d-flex justify-content-between col-6 align-items-center py-4">
                                <div>
                                  <h5 className="mb-0">Experience</h5>
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-blue btn-sm"
                                    onClick={() => {
                                      setShowCandidateExperienceModal(true);
                                    }}
                                  >
                                    Add Experience
                                  </button>
                                </div>
                              </div>
                              <div className="candidate-experience-container grid-container">
                                {currentCandidate?.experiences?.map(
                                  (experience) => {
                                    const startYear = new Date(
                                      experience?.startDate
                                    ).getFullYear();
                                    const endYear = experience?.endDate
                                      ? new Date(
                                          experience?.endDate
                                        ).getFullYear()
                                      : new Date().getFullYear();

                                    const yearsOfExperience =
                                      endYear - startYear;

                                    return (
                                      <div
                                        key={experience?.guid}
                                        className="experience-card"
                                      >
                                        <div className="experience-header">
                                          <h3 className="experience-title">
                                            {experience?.title}
                                          </h3>
                                          <div className="button-container">
                                            <button
                                              type="button"
                                              className="btn p-1"
                                              onClick={() => {
                                                setExperienceUpdateMode(true);
                                                setSelectedExperience(
                                                  experience
                                                );
                                                setShowCandidateExperienceModal(
                                                  true
                                                );
                                              }}
                                            >
                                              <img src={updateIcon} alt="" />
                                            </button>
                                            <button
                                              type="button"
                                              className="btn p-1"
                                              onClick={() => {
                                                setSelectedExperienceGuid(
                                                  experience?.guid
                                                );
                                                setShowDeleteModal(true);
                                              }}
                                            >
                                              <img src={deleteIcon} alt="" />
                                            </button>
                                          </div>
                                          <p className="company-name">
                                            {experience?.company?.name}
                                          </p>
                                        </div>
                                        <div className="experience-dates">
                                          <p className="experience-duration mb-1">
                                            {`${new Date(
                                              experience?.startDate
                                            ).toLocaleString("default", {
                                              year: "numeric",
                                              month: "long",
                                              day: "numeric",
                                            })} - ${
                                              experience?.endDate
                                                ? new Date(
                                                    experience?.endDate
                                                  ).toLocaleString("default", {
                                                    year: "numeric",
                                                    month: "long",
                                                    day: "numeric",
                                                  })
                                                : new Date().toLocaleString(
                                                    "default",
                                                    {
                                                      year: "numeric",
                                                      month: "long",
                                                      day: "numeric",
                                                    }
                                                  )
                                            }`}
                                          </p>
                                          <p className="experience-year-difference mb-1">{`${yearsOfExperience} yr`}</p>
                                        </div>
                                        <p className="mb-2">
                                          {experience?.description}
                                        </p>
                                        <p className="work-type">
                                          {experience?.workType?.name}
                                        </p>
                                        <div className="skills-section">
                                          {experience?.skills?.map((skill) => (
                                            <span className="candidate-skill-lable">
                                              {skill?.name}
                                            </span>
                                          ))}
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              <CandidateExperienceModal
                                setShowModal={setShowCandidateExperienceModal}
                                showModal={showCandidateExperienceModal}
                                isUpdateMode={experienceUpdateMode}
                                selectedExperience={selectedExperience}
                                setExperienceUpdateMode={
                                  setExperienceUpdateMode
                                }
                                setSelectedExperience={setSelectedExperience}
                              />
                              <ConfirmationModal
                                confirmResubmit={
                                  removeCandidateExperienceOnClick
                                }
                                showModal={showDeleteModal}
                                closeModal={() => {
                                  setShowDeleteModal(false);
                                }}
                                title={
                                  "Do you want to remove this experience ?"
                                }
                                type={"Experience"}
                              />
                            </div>

                            <h5 className="mt-4">Attachments</h5>
                            <hr className="my-1 mb-2" />

                            <div className="col-md-6">
                              <div className="attchment-container">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-blue btn-sm"
                                      onClick={() => {
                                        setFileUploadModal(true);
                                      }}
                                    >
                                      Upload Attachment
                                    </button>
                                  </div>
                                  <div>
                                    {/* <FormGroup>
                                      <Input
                                        type="select"
                                        id="file-Type"
                                        value={attachmentTypeId}
                                        onChange={(e) =>
                                          setAttachmentTypeId(e.target.value)
                                        }
                                      >
                                        <option value={2}>Resume</option>
                                        <option value={3}>Cover Letter</option>
                                      </Input>
                                    </FormGroup> */}
                                  </div>
                                </div>
                              </div>
                              <FileUploadModal
                                refetchAttachments={refetchCandidateAttachments}
                                isOpen={fileUploadModal}
                                toggle={toggleModal}
                              />
                              {candidateAttachments?.length > 0 && (
                                <table align="center" className="table mt-4">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Type</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {candidateAttachments?.map((attachment) => (
                                      <tr key={attachment?.guid}>
                                        <td>{attachment?.fileName}</td>
                                        <td>{attachment?.type?.name}</td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn p-0"
                                            onClick={() => {
                                              setAttachmentUrl("");
                                              handleAttachmentView(
                                                attachment?.attachmentGuid
                                              );
                                            }}
                                          >
                                            <ViewIcon width={25} height={25} />
                                          </button>
                                          <button
                                            type="button"
                                            className="btn p-0 mx-2"
                                            onClick={() =>
                                              removeCandidateAttachmentOnClick(
                                                attachment?.attachmentGuid
                                              )
                                            }
                                          >
                                            <DeleteIcon
                                              width={20}
                                              height={20}
                                            />
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>

                    {/* <h5 className="mt-3">Terms & Conditions</h5>
                    <hr className="my-1 mb-4" />
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Inventore dolor atque quas architecto et? Ab excepturi
                      sunt aspernatur fuga ipsum repudiandae laborum, in
                      quibusdam veritatis quidem ullam dolore veniam
                      exercitationem. Lorem ipsum dolor sit amet consectetur
                      adipisicing elit. Culpa porro, dolore facilis incidunt
                      tempora nesciunt fugiat at qui excepturi. Blanditiis
                      facere repellendus voluptatem quam ipsam maxime,
                      reiciendis quas consectetur atque. Lorem ipsum dolor sit
                      amet consectetur adipisicing elit. Unde at deleniti ullam!
                      Repellat ducimus tenetur velit! Quae aut facilis quibusdam
                      et hic voluptatibus modi voluptas, tenetur, officiis
                      accusantium saepe maiores.
                    </p>

                    <div className="form-check mt-4">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="acceptConditions"
                        defaultChecked={update}
                        value={isConditionsAccepted}
                        onChange={(e) =>
                          setisConditionsAccepted(!isConditionsAccepted)
                        }
                      />
                      <label
                        className="form-check-label fw-bold"
                        htmlFor="acceptConditions"
                      >
                        I accept the terms and conditions
                      </label>
                    </div> */}

                    <hr className="my-5" />

                    <div className="col-12 mb-5">
                      <div className="row justify-content-between">
                        <div className="col-12 col-md-3">
                          <button
                            className="btn btn-outline btn-basic mb-3"
                            type="button"
                            onClick={cancelAndGoBack}
                          >
                            Cancel & Go Back
                          </button>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="row">
                            <div className="col-12 col-md-6">
                              {/* <button
                                className="btn btn-outline btn-basic mb-3"
                                type="reset"
                                onClick={clearAll}
                              >
                                Clear All
                              </button> */}
                            </div>
                            <div className="col-12 col-md-6">
                              <button
                                className="btn btn-blue btn-basic mb-3"
                                type="submit"
                              >
                                {registerIsLoading ||
                                createProfileIsLoading ||
                                isUpdateUserLoading ||
                                isUpdateProfileLoading ? (
                                  <DotSpinner />
                                ) : update ? (
                                  "Update Profile"
                                ) : (
                                  "Create Profile"
                                )}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateProfile;
