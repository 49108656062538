import React, { useState } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNow";

const Resources = () => {
  const [resources, setResources] = useState([
    {
      id: 1,
      title: "How to prepare for and Interview",
      resourceUrl:
        "https://joinhandshake.com/blog/students/how-to-prepare-for-an-interview/",
    },
    {
      id: 2,
      title: "How to prepare CV",
      resourceUrl:
        "https://www.indeed.com/career-advice/resumes-cover-letters/how-to-write-a-cv",
    },
    {
      id: 3,
      title: "How to be hired",
      resourceUrl:
        "https://www.indeed.com/career-advice/starting-new-job/getting-hired",
    },
    {
      id: 4,
      title: "What does a employer expected from a job seeker",
      resourceUrl:
        "https://www.indeed.com/career-advice/finding-a-job/qualities-employers-want#:~:text=Being%20self%2Dassured%20is%20a,and%20know%20what%20they%20want.",
    },
    {
      id: 5,
      title: "Commonly asked questions",
      resourceUrl:
        "https://www.themuse.com/advice/interview-questions-and-answers",
    },
  ]);

  const [selectedResource, setSelectedResource] = useState(resources[0]);
  const resourcesPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const handleResourceSelection = (resourceId) => {
    const [resource] = resources.filter(
      (resource) => resource.id === resourceId
    );
    setSelectedResource(resource);
  };

  const totalResources = resources.length;
  const totalPages = Math.ceil(totalResources / resourcesPerPage);

  const paginatedResources = resources.slice(
    (currentPage - 1) * resourcesPerPage,
    currentPage * resourcesPerPage
  );

  const handleGoForward = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGoBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-center content-container">
          <div className="col-12 ">
            <div className="mt-2" style={{ paddingTop: "7em" }}></div>
            <div
              className="card find-jobs-container"
              style={{
                borderRadius: "1em",
                minHeight: "70vh",
                maxHeight: "auto",
              }}
            >
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-12 col-md-5">
                    <div className="list-group list-group-flush job-list">
                      <div className="pagination d-flex justify-content-between">
                        <div className="">Showing {totalResources} results</div>
                        <div className="">
                          <span>
                            {currentPage * resourcesPerPage -
                              resourcesPerPage +
                              1}{" "}
                            -{" "}
                            {currentPage === totalPages
                              ? totalResources
                              : currentPage * resourcesPerPage}{" "}
                            of {totalResources}
                          </span>
                          <button
                            onClick={handleGoBack}
                            className="pagination-button"
                          >
                            <div className="svg-container">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-chevron-left"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                />
                              </svg>
                            </div>
                          </button>
                          <button
                            onClick={handleGoForward}
                            className="pagination-button"
                          >
                            <div className="svg-container">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-chevron-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className="list-wrapper p-2">
                        {paginatedResources &&
                          paginatedResources.map((resource) => (
                            <div
                              key={resource.id}
                              className={`list-group-item list-group-item-action py-3 lh-sm border-top-0 border-end-0 border-start-0 ${
                                resource.id === selectedResource.id
                                  ? "selected-job"
                                  : ""
                              }`}
                              onClick={() =>
                                handleResourceSelection(resource.id)
                              }
                            >
                              <div className="d-flex w-100 align-items-center justify-content-between">
                                <h5 className="mb-2 active">
                                  {resource.title}
                                </h5>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <iframe
                      style={{ width: "100%", height: "70vh" }}
                      src={selectedResource.resourceUrl}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
