import React, { useState, useEffect } from "react";
import JobInfo from "../../components/common/JobInfo/JobInfo";
import { useSelector } from "react-redux";
import {
  useApplyJobMutation,
  useGetAppliedJobsQuery,
} from "../../features/jobs/jobSlice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { format } from "date-fns";
import AppliedJobInfo from "../../components/common/AppliedJobInfo/AppliedJobInfo";
import { appliedJobStatus } from "../../enums/statuses";

const AppliedJobs = () => {
  const [jobData, setJobData] = useState([]);
  const [selectedJob, setSelectedJob] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tenant, setTenant] = useState("");

  const { data, isLoading, isSuccess, isError } = useGetAppliedJobsQuery({
    page: currentPage,
    pageSize,
  });

  useEffect(() => {
    if (data) {
      const { jobs, pagination } = data;
      const { itemsCount, page, pageSize, total, totalPages } = pagination;
      if (jobs) {
        const data = jobs.ids.map((id) => {
          return jobs.entities[id];
        });
        if(!selectedJob.length > 0 ){
          setSelectedJob(data[0]?.guid);
        }
        setTenant(data[0]?.job?.company?.schema);
        setTotalJobs(total);
        setCurrentPage(page);
        setTotalPages(totalPages);
        setJobData(data);
      }
    }
  }, [data]);

  const handleJobSelection = (guid) => {
    setSelectedJob(guid);
  };

  const handleGoForward = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGoBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-center content-container">
          <div className="col-12">
            <div className="mt-2" style={{ paddingTop: "7em" }}></div>

            <div
              className="card find-jobs-container"
              style={{
                borderRadius: "1em",
                minHeight: "70vh",
                maxHeight: "auto",
              }}
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : jobData?.length > 0 ? (
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-12 col-md-5">
                      <div className="list-group list-group-flush job-list">
                        <div className="pagination d-flex justify-content-between">
                          <div className="">Showing {totalJobs} results</div>
                          <div className="">
                            <span>
                              {currentPage * pageSize - pageSize + 1} -{" "}
                              {currentPage === totalPages
                                ? totalJobs
                                : currentPage * pageSize}{" "}
                              of {totalJobs}
                            </span>
                            <button
                              onClick={handleGoBack}
                              className="pagination-button"
                            >
                              <div className="svg-container">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-chevron-left"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                  />
                                </svg>
                              </div>
                            </button>
                            <button
                              onClick={handleGoForward}
                              className="pagination-button"
                            >
                              <div className="svg-container">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-chevron-right"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                  />
                                </svg>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="list-wrapper p-2">
                          {jobData &&
                            jobData.map(({ job, appliedOn, guid, status }) => (
                              <div
                                key={guid}
                                className={`list-group-item list-group-item-action py-3 lh-sm border-top-0 border-end-0 border-start-0 ${
                                  guid === selectedJob ? "selected-job" : ""
                                }`}
                                onClick={() => handleJobSelection(guid)}
                              >
                                <div className="d-flex w-100 align-items-center justify-content-between">
                                  <h5 className="mb-2 active">{job?.title}</h5>
                                  <span
                                    className={`badge ${
                                      status?.guid === appliedJobStatus?.APPLIED
                                        ? "applied-status"
                                        : status?.guid ===
                                          appliedJobStatus?.APPROVED
                                        ? "approved-status"
                                        : status?.guid ===
                                          appliedJobStatus?.REJECTED
                                        ? "rejected-status"
                                        : status?.guid ===
                                          appliedJobStatus?.WITHDRAW
                                        ? "withdraw-status"
                                        : status?.guid ===
                                          appliedJobStatus?.PENDING
                                        ? "pending-status"
                                        : status?.guid ===
                                          appliedJobStatus?.HIRED
                                        ? "hired-status"
                                        : "bg-secondary"
                                    }`}
                                  >
                                    {status?.name}
                                  </span>
                                </div>
                                <div className="col-10 mb-1">
                                  <p className="fs-6">
                                    {job?.company?.name ||
                                    job?.company?.location
                                      ? `${job?.company?.name}, ${job?.company?.location}`
                                      : ""}
                                  </p>
                                </div>
                                <p className="d-flex align-items-center justify-content-end m-0">
                                  <span>
                                    Applied On :
                                    <small className="badge text-bg-warning ms-2">
                                      {appliedOn
                                        ? format(
                                            new Date(appliedOn),
                                            "dd-MM-yyyy"
                                          )
                                        : "-"}
                                    </small>
                                  </span>
                                </p>
                                {/* <p className="text-end m-0 small">
                                  Applied On:
                                  <span className="badge text-bg-secondary ms-2">
                                    {format(new Date(appliedOn), "dd-MM-yyyy")}
                                  </span>
                                </p> */}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-7">
                      <AppliedJobInfo jobGuid={selectedJob} tenant={tenant} />
                    </div>
                  </div>
                </div>
              ) : (
                <h6 className="text-center mt-5">
                  You Don't have any applied jobs
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedJobs;
