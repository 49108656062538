import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ToastWrapper, {
  errorToast,
  successToast,
} from "../ToastWrapper/ToastWrapper";
import Kanban from "../Kanban/Kanban";
import {
  useGetAppliedJobByGuidQuery,
  useWithdrawAppliedJobMutation,
} from "../../../features/jobs/jobSlice";
import DotSpinner from "../LoadingSpinner/DotSpinner";
import { formatToCurrency } from "../../../utils/methods";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import meetIcon from "../../../assets/icons/meet.svg";
import {
  appliedJobStatus,
  interviewPipelineStageStatus,
} from "../../../enums/statuses";
import { format } from "date-fns";
const AppliedJobInfo = ({ jobGuid, tenant }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [interviewStage, setInterviewStage] = useState({});

  const {
    data: jobDetails,
    isLoading: jobDetailsIsLoading,
    isFetching: jobDetailsIsFetching,
    isError: jobDetailsIsError,
    isSuccess: jobDetailsIsSuccess,
    error: jobDetailsError,
  } = useGetAppliedJobByGuidQuery(
    { guid: jobGuid, tenant },
    {
      skip: !jobGuid || !tenant,
    }
  );

  useEffect(() => {
    if (jobDetails) {
      setInterviewStage(jobDetails?.interviewPipelineStages[0]);
      setSelectedJob(jobDetails);
    }
    if (jobDetailsError) {
      errorToast(
        jobDetailsError?.data?.Message || jobDetailsError?.data?.title
      );
    }
  }, [jobDetails]);

  const [
    withdrawAppliedJob,
    {
      isLoading: isWithdrawLoading,
      isSuccess: isWithdrawSuccess,
      isError: isWithdrawError,
      error: withdrawError,
    },
  ] = useWithdrawAppliedJobMutation();

  const handleWithdraw = async () => {
    try {
      const response = await withdrawAppliedJob({
        guid: jobGuid,
        tenant,
      }).unwrap();
      successToast("Application Widthdrawn succesfull");
      setShowConfirmationModal(false);
    } catch (error) {
      errorToast(
        error?.data?.Message ||
          error?.data?.title ||
          "Something Went wrong while withdrawing your application"
      );
    }
  };

  const cancelWithdrawal = () => {
    setShowConfirmationModal(false);
  };

  const handleStageSelect = (data) => {
    const selectedStage = selectedJob?.interviewPipelineStages?.find(
      (stage) => stage?.guid === data?.Id
    );
    setInterviewStage(selectedStage);
  };

  return (
    <div
      className="job-desc-container pe-3 m-1"
      style={{ maxHeight: "70vh", overflow: "auto", position: "relative" }}
    >
      {jobDetailsIsFetching && <LoadingSpinner />}
      <ToastWrapper />
      {selectedJob ? (
        <>
          <section className="pt-3 border-bottom mb-3 job-title-section">
            <h5>{selectedJob?.job?.title}</h5>
            <div className="d-flex justify-content-between mb-2">
              <p>
                {formatToCurrency(selectedJob?.job?.minSalary)}
                {" - "}
                {formatToCurrency(selectedJob?.job?.maxSalary)}
              </p>
              {selectedJob?.status?.guid !== appliedJobStatus.WITHDRAW && (
                <button
                  className="btn btn-danger btn-basic btn-withdraw"
                  onClick={() => setShowConfirmationModal((prev) => !prev)}
                >
                  Withdraw Application
                </button>
              )}
            </div>
            <section>
              <h5 className="mb-3">Application Progress</h5>
              <div className="row">
                <div className="col-12">
                  <Kanban
                    applicationProgress={selectedJob?.interviewPipelineStages}
                    handleStageSelect={handleStageSelect}
                  />
                </div>
              </div>
            </section>
          </section>
          {interviewStage && (
            <div>
              <h6 className="mt-3">
                {interviewStage?.jobInterviewPipelineStage?.name}
              </h6>
              <div>
                <p>
                  Meeting URL: &nbsp;
                  {interviewStage?.meetUrl ? (
                    <a
                      href={interviewStage?.meetUrl}
                      title="Click Here to join"
                    >
                      <img src={meetIcon} alt="Meet" width={36} />
                      {interviewStage?.meetUrl}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p>
                  Date Assigned: &nbsp;
                  {interviewStage?.dateAssigned ? (
                    <span
                      className="btn btn-outline-secondary btn-sm"
                      style={{
                        cursor: "default",
                      }}
                    >
                      {format(
                        new Date(interviewStage?.dateAssigned),
                        "dd-MM-yyyy"
                      )}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p>
                  Progress Status: &nbsp;
                  {interviewStage?.status ? (
                    <span
                      className={`badge ${
                        interviewStage?.status?.guid ===
                        interviewPipelineStageStatus?.COMPLETED
                          ? "completed-status"
                          : interviewStage?.status?.guid ===
                            interviewPipelineStageStatus?.SCHEDULED
                          ? "scheduled-status"
                          : interviewStage?.status?.guid ===
                            interviewPipelineStageStatus?.PENDING
                          ? "pending-status"
                          : interviewStage?.status?.guid ===
                            interviewPipelineStageStatus?.REJECTED
                          ? "rejected-status"
                          : interviewStage?.status?.guid ===
                            interviewPipelineStageStatus?.INCOMPLETED
                          ? "incompleted-status"
                          : "bg-dark"
                      } `}
                    >
                      {interviewStage?.status?.name}
                    </span>
                  ) : (
                    "N/A"
                  )}
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        "No don't have any applied jobs"
      )}

      <Modal isOpen={showConfirmationModal} toggle={cancelWithdrawal}>
        <ModalHeader toggle={cancelWithdrawal}>Confirm Withdrawal</ModalHeader>
        <ModalBody>
          Are you sure you want to withdraw your application?
        </ModalBody>
        <ModalFooter>
          <div className="row w-100 justify-content-between">
            <div className="col-12 col-md-5">
              <button
                className="btn btn-basic btn-danger mb-1"
                onClick={() => handleWithdraw(selectedJob)}
              >
                {isWithdrawLoading ? <DotSpinner /> : "Yes, Withdraw"}
              </button>
            </div>
            <div className="col-12 col-md-5">
              <button
                className="btn btn-basic btn-blue mb-1"
                onClick={cancelWithdrawal}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AppliedJobInfo;
