import { React, useState, useEffect, memo } from "react";
import { useGetSkillsQuery } from "../../features/staticData/staticData";
import {
  useAddCandidateSkillMutation,
  useRemoveCandidateSkillMutation,
} from "../../features/candidate/candidateSlice";
import Multiselect from "multiselect-react-dropdown";
import ToastWrapper, {
  successToast,
  errorToast,
} from "../../components/common/ToastWrapper/ToastWrapper";

const CandidateSkill = memo(({ initialSelectedSkills, isUpdate, section, setSelectedSkillGuids }) => {
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState("");
  const [removedSkill, setRemovedSkill] = useState("");

  const [
    addCandidateSkill,
    { isLoading: addCandidateSkillIsLoading, addCandidateSkillIsSuccess },
  ] = useAddCandidateSkillMutation();

  const [
    removeCandidateSkill,
    { isLoading: removeCandidateSkillIsLoading, removeCandidateSkillIsSuccess },
  ] = useRemoveCandidateSkillMutation();

  const {
    data: candidateSkills,
    isLoading: candidateSkillsIsLoading,
    isFetching: candidateSkillsIsFetching,
    error: candidateSkillsError,
  } = useGetSkillsQuery();

  const handleSkillAdd = (selectedList, selectedItem) => {
    setSelectedSkills((prevSkills) => [
      ...new Set([...prevSkills, selectedItem]),
    ]);
    setSelectedSkill(selectedItem?.guid);
  };

  const handleSkillRemove = (selectedList, removedItem) => {
    // console.log("rem", removedItem);
    setSelectedSkills((prevSkills) =>
      prevSkills.filter((skill) => skill?.guid !== removedItem.guid)
    );
    setRemovedSkill(removedItem?.guid);
  };

  useEffect(() => {
    if (candidateSkills) {
      setSkills(candidateSkills);
    }
    if (candidateSkillsError) {
      return;
    }
  }, [candidateSkills, candidateSkillsError]);

  useEffect(() => {
    if (section) {
      const skillGuids = Array.isArray(selectedSkills)
        ? selectedSkills.map((skill) => skill?.guid)
        : [];
      setSelectedSkillGuids(skillGuids);
    }
  }, [section, selectedSkills]);

  useEffect(() => {
    if (isUpdate) {
      setSelectedSkills(initialSelectedSkills);
    }
    if(section){
      setSelectedSkills(initialSelectedSkills);
    }
  }, [isUpdate, initialSelectedSkills, section]);

  useEffect(() => {
    if (selectedSkill && !section) {
      addCandidateSkillOnClick();
    }
    if (removedSkill && !section) {
      removeCandidateSkillOnClick();
    }
  }, [selectedSkill, removedSkill]);

  const addCandidateSkillOnClick = async () => {
    try {
      const response = await addCandidateSkill({
        guid: selectedSkill,
      }).unwrap();
      if (response?.error) {
        errorToast(response?.error?.data?.title || "Something Went wrong");
        return;
      }
      // successToast("Skill Added Successfully..!");
      setSelectedSkill("");
    } catch (error) {
      errorToast(error?.data?.Message);
      setSelectedSkill("");
    }
  };

  const removeCandidateSkillOnClick = async () => {
    try {
      const response = await removeCandidateSkill({
        guid: removedSkill,
      }).unwrap();
      if (response?.error) {
        errorToast(response?.error?.data?.title || "Something Went wrong");
        return;
      }
      // successToast("Skill Removed Successfully..!");
      setRemovedSkill("");
    } catch (error) {
      errorToast(error?.data?.Message);
      setRemovedSkill("");
    }
  };

  return (
    <div className="col-12">
      <label htmlFor="skills" className="form-label">
        Skills
      </label>
      <Multiselect
        options={skills}
        selectedValues={selectedSkills}
        onSelect={handleSkillAdd}
        onRemove={handleSkillRemove}
        displayValue="name"
        closeOnSelect={true}
        placeholder="Select skills"
        avoidHighlightFirstOption="true"
        style={{
          chips: {
            background: "#2F00FE",
          },
          searchBox: {
            border: "none",
            borderBottom: "1px solid #2F00FE",
            borderRadius: "0px",
          },
        }}
      />
    </div>
  );
});

export default CandidateSkill;
