import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import {
  useGetAttachmentsQuery,
  useAddAttachmentMutation,
  useAddCandidateAttachmentMutation,
  useAddCandidateSkillMutation,
  useRemoveCandidateSkillMutation,
} from "../../features/candidate/candidateSlice";
import uploadIcon from "../../assets/icons/upload.svg";
import ToastWrapper, {
  successToast,
  errorToast,
} from "../../components/common/ToastWrapper/ToastWrapper";
import LoadingSpinner from "../common/LoadingSpinner/LoadingSpinner";

const FileUploadModal = ({ isOpen, toggle, refetchAttachments }) => {
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  const [fileType, setFileType] = useState(2);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);

  const [
    addAttachment,
    { isLoading: addAttachmentIsLoading, addAttachmentIsSuccess },
  ] = useAddAttachmentMutation();

  const [
    addCandidateAttachment,
    {
      isLoading: addCandidateAttachmentIsLoading,
      addCandidateAttachmentIsSuccess,
    },
  ] = useAddCandidateAttachmentMutation();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > MAX_FILE_SIZE) {
      errorToast(
        `File size should be less than ${MAX_FILE_SIZE / (1024 * 1024)} MB`
      );
      return;
    } else {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const attachmentResponse = await addAttachmentOnClick();
      if (attachmentResponse?.error) {
        errorToast(attachmentResponse?.Message || "Something Went wrong");
        return;
      }
    }
    refetchAttachments();
    toggle();
  };

  useEffect(() => {
    if (!isOpen) {
      setFile(null);
      setFileName("");
    }
  }, [isOpen]);

  const addAttachmentOnClick = async () => {
    try {
      const response = await addAttachment({
        file: file,
      }).unwrap();
      if (response?.error) {
        errorToast(response?.error?.data?.title || "Something Went wrong");
        return;
      }

      if (response?.guid) {
        await addCandidateAttachmentOnClick(response?.guid);
      }
      setFile(null);
    } catch (error) {
      errorToast(error?.data?.Message);
    }
  };

  const addCandidateAttachmentOnClick = async (attachmentGuid) => {
    try {
      const response = await addCandidateAttachment({
        attachmentTypeId: fileType,
        attachmentGuid: attachmentGuid,
      }).unwrap();
      if (response?.error) {
        errorToast(response?.error?.data?.title || "Something Went wrong");
        return;
      }
      successToast("Attachment uploaded Successfully..!");
    } catch (error) {
      errorToast(error?.data?.Message);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      {(addAttachmentIsLoading || addCandidateAttachmentIsLoading) && (
        <LoadingSpinner />
      )}
      <ModalHeader toggle={toggle}>Upload Attachment</ModalHeader>
      <ModalBody className="px-4">
        <FormGroup>
          <Label for="fileType">Select Attachment Type</Label>
          <Input
            type="select"
            id="fileType"
            value={fileType}
            onChange={(e) => setFileType(e.target.value)}
          >
            <option value={2}>Resume</option>
            <option value={3}>Cover Letter</option>
          </Input>
        </FormGroup>
        <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
          <div>{fileName || "No file selected"}</div>
          <div className="upload-icon-container" style={{ cursor: "pointer" }}>
            <img
              width={25}
              height={25}
              src={uploadIcon}
              alt="Upload"
              onClick={() => {
                document.querySelector(".custom-file-input").click();
              }}
              className="upload-icon"
            />
            <input
              className="custom-file-input"
              type="file"
              hidden
              accept=".pdf,.doc,.docx"
              onClick={() => (fileRef.current = "")}
              onChange={handleFileChange}
              style={{ display: "none" }} // Hide the file input
            />
          </div>
        </div>
        <strong className="pt-1">Only .pdf attachments are allowed</strong>
      </ModalBody>
      <ModalFooter>
        <button
          style={{ backgroundColor: "#2f00fe", color: "white" }}
          onClick={handleUpload}
          disabled={!file}
          className="btn"
        >
          Upload File
        </button>
        <button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default FileUploadModal;
