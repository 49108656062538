import React from "react";
import {
  KanbanComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-kanban";

import "./Kanban.css";
import { useEffect, useState } from "react";

const Kanban = ({ applicationProgress, handleStageSelect = () => {} }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (applicationProgress) {
      setData(
        applicationProgress.map((progress, index) => ({
          Id: progress.guid,
          Status: progress.status.name,
          Title: progress.jobInterviewPipelineStage.name,
          Step: index,
        }))
      );
    }
  }, [applicationProgress]);

  const columnTemplate = (props) => {
    return (
      <div
        className="header-template-wrap"
        style={{ justifyContent: "center" }}
      >
        <div className="header-text">{props.headerText}</div>
      </div>
    );
  };
  const cardTemplate = (props) => {
    return (
      <div
        className={`card-template rounded text-white ${
          props.Status === "Pass"
            ? "passed"
            : props.Status === "Failed"
            ? "failed"
            : props.Status === "Incomplete"
            ? "incomplete"
            : props.Status.trim() === "Awaiting"
            ? "awaiting"
            : "default"
        }`}
      >
        <div
          className="e-card-header"
          onClick={() => handleStageSelect(props)}
          role="button"
        >
          <div className="e-card-header-caption">
            <div className="e-card-header-title e-tooltip-text">
              {props.Status}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {data.length > 0 ? (
        <KanbanComponent
          id="kanban"
          key={data.length}
          dataSource={data}
          keyField="Step"
          cardSettings={{
            contentField: "Summary",
            headerField: "Title",
            template: cardTemplate,
            allowEditing: false,
          }}
          allowDragAndDrop={false}
          dialogOpen={(args) => {
            args.cancel = true;
          }}
        >
          <ColumnsDirective>
            {data?.map((item) => (
              <ColumnDirective
                key={item.Id}
                headerText={item.Title}
                keyField={item.Step}
                showItemCount={false}
                template={columnTemplate}
              />
            ))}
          </ColumnsDirective>
        </KanbanComponent>
      ) : (
        "No application progress"
      )}
    </>
  );
};

export default Kanban;
