import React from "react";
import "./LandingPage.css";
import mainlogo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/auth/authSlice";
const LandingPage = () => {
  const isUserLoggedIn = useSelector(selectCurrentUser) ? true : false; 
  return (
    <div className="bodyClass">
      <div className="mainBanner">
        <div className="mainLogo" id="">
          <img id="mainLogo" src={mainlogo} alt="logo" />
        </div>

        <div className="title1" id="missionHead">
          Our Mission Statement
        </div>
        <div className="text1" id="missionText">
          <p>
            At Intellimorph-Talent our goal is to connect hiring managers with
            the very best talent, and candidates to the best fitting roles,
            using a people-centric approach. We want to revolutionise the
            staffing landscape by providing a complete and transparent talent
            service that puts people first.
          </p>
        </div>
      </div>

      <div className="row boxArea">
        <div className="col-12 col-md-4">
          <div className="infoBox" id="boxOne">
            <div className="infoHead">People-First Approach</div>
            <div className="infoText">
              Our team is dedicated to providing a superior service, including
              personalised support, throughout the recruitment process. Whether
              your journey is that of a candidate looking for the best fitting
              role, or of an employer looking for the best talent, join us on
              the journey in putting people first!
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="infoBox" id="boxTwo">
            <div className="infoHead">Candidate Role Fit Test</div>
            <div className="infoText">
              Our Candidate Role Fit Test ensures matching a candidate with the
              ideal role for them which is important because it leads to job
              satisfaction, better job performance, career growth and
              development, and a positive organisational culture and above all,
              happiness!
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="infoBox" id="boxThree">
            <div className="infoHead">Equal Opportunities</div>
            <div className="infoText">
              With a transparent recruitment process, all applicants are given
              an equal opportunity to compete for the position based on their
              qualifications, skills, and experience. This ensures that the best
              candidate is selected, and there is no favouritism or
              discrimination involved in the selection process.
            </div>
          </div>
        </div>
      </div>

      <div id="doWhatArea">
        <div className="title1" id="doWhatHead">
          Do what you are made for
        </div>
        <div className="text1" id="doWhatText">
          <p>
            By leveraging the power of technology and data driven processes, we
            provide a single source of truth for talent acquisition. We ensure
            that technology, processes and people-centric practices are
            harmonised together to provide a transparent talent service that
            results in top talent being hired, while being a great experience,
            not only for the hiring managers, but for candidates too.
          </p>

          <p>
            Our solutions streamline the talent acquisition process for
            businesses of all sizes, ensuring the best candidates are placed in
            front of you. We minimise your effort in finding the best fitting
            candidates, leaving you to make informed decisions on your hiring
            needs, and to get the right people in place quickly.
          </p>
        </div>
      </div>

     { !isUserLoggedIn ? <Link
        className="buttonBig"
        to="/login"
        title="Get Started"
      >
        Let's get started!
      </Link> : ""}
      {/* <Footer /> */}
    </div>
  );
};

export default LandingPage;
