import { da } from "date-fns/locale";
import { apiSlice } from "../../app/api/apiSlice";

export const candiateSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAttachments: builder.query({
      query: (attachmentTypeId) =>
        `candidates/attachments?${
          attachmentTypeId ? `AttachmentTypeId=${attachmentTypeId}` : ""
        }`,
      providesTags: [{ type: "Attachments", id: "LIST" }],
    }),
    addAttachment: builder.mutation({
      query: (data) => {
        const formData = new FormData();
        formData.append("file", data?.file);
        return {
          url: `/attachments`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Attachments", id: "LIST" }],
    }),
    addCandidateAttachment: builder.mutation({
      query: (data) => ({
        url: `/candidates/attachments`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: [{ type: "Attachments", id: "LIST" }],
    }),
    removeCandidateAttachment: builder.mutation({
      query: (guid) => ({
        url: `/attachments/${guid}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Attachments", id: "LIST" }],
    }),
    addCandidateSkill: builder.mutation({
      query: (data) => ({
        url: `/candidates/skills`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: [{ type: "Candidate" }],
    }),
    RemoveCandidateSkill: builder.mutation({
      query: (data) => ({
        url: `/candidates/skills/${data?.guid}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Candidate" }],
    }),
    uploadCandidateProfilePicture: builder.mutation({
      query: (data) => ({
        url: `/candidates/profile-picture`,
        method: "PUT",
        formData: true,
        body: data.image,
      }),
    }),
    addCandidateExperience: builder.mutation({
      query: (data) => ({
        url: `/candidates/experiences`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: [{ type: "Candidate" }],
    }),
    RemoveCandidateExperience: builder.mutation({
      query: (data) => ({
        url: `/candidates/experiences/${data?.guid}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Candidate" }],
    }),
    updateCandidateExperience: builder.mutation({
      query: (data) => ({
        url: `/candidates/experiences/${data?.guid}`,
        method: "PUT",
        body: {
          ...data?.experienceDetails,
        },
      }),
      invalidatesTags: [{ type: "Candidate" }],
    }),
  }),
});

export const {
  useGetAttachmentsQuery,
  useAddAttachmentMutation,
  useAddCandidateAttachmentMutation,
  useAddCandidateSkillMutation,
  useRemoveCandidateSkillMutation,
  useRemoveCandidateAttachmentMutation,
  useUploadCandidateProfilePictureMutation,
  useAddCandidateExperienceMutation,
  useRemoveCandidateExperienceMutation,
  useUpdateCandidateExperienceMutation
} = candiateSlice;
