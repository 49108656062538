import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const jobAdapter = createEntityAdapter({
  selectId: (job) => job.guid,
});

const appliedJobAdapter = createEntityAdapter({
  selectId: (job) => job.guid,
});

const jobsInitialState = jobAdapter.getInitialState();
const appliedJobsInitialState = appliedJobAdapter.getInitialState();

export const jobSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: ({ page, pageSize, categoryId, workTypeId, query }) => {
        const queryParams = [];

        if (page) queryParams.push(`page=${page}`);
        if (pageSize) queryParams.push(`pageSize=${pageSize}`);
        if (categoryId) queryParams.push(`categoryId=${categoryId}`);
        if (workTypeId) queryParams.push(`workTypeId=${workTypeId}`);
        if (query) queryParams.push(`query=${query}`);

        const queryString = queryParams.length
          ? `?${queryParams.join("&")}`
          : "";
        return `/jobs${queryString}`;
      },
      transformResponse: (responseData) => {
        const {
          items: loadedJobs,
          itemsCount,
          page,
          pageSize,
          total,
          totalPages,
        } = responseData;

        return {
          jobs: jobAdapter.setAll(jobsInitialState, loadedJobs),
          pagination: { itemsCount, page, pageSize, total, totalPages },
        };
      },
      providesTags: (result, error, arg) => {
        if (!result) return [{ type: "Jobs", id: "LIST" }];

        return [
          { type: "Jobs", id: "LIST" },
          ...result.jobs.ids.map((id) => ({ type: "Jobs", id })),
        ];
      },
    }),
    getJobByGuid: builder.query({
      query: ({ guid, tenant }) => ({
        url: `/jobs/${guid}`,
        headers: {
          tenant: `${tenant}`,
        },
      }),
      providesTags: [{ type: "Job" }],
    }),
    getAppliedJobs: builder.query({
      query: ({ page, pageSize }) => {
        const queryParams = [];

        if (page) queryParams.push(`page=${page}`);
        if (pageSize) queryParams.push(`pageSize=${pageSize}`);

        const queryString = queryParams.length
          ? `?${queryParams.join("&")}`
          : "";

        return `/candidates/applied-jobs${queryString}`;
      },
      transformResponse: (responseData) => {
        const {
          items: loadedJobs,
          itemsCount,
          page,
          pageSize,
          total,
          totalPages,
        } = responseData;
        return {
          jobs: appliedJobAdapter.setAll(appliedJobsInitialState, loadedJobs),
          pagination: {
            itemsCount,
            page,
            pageSize,
            total,
            totalPages,
          },
        };
      },
      providesTags: (result, error, arg) => {
        if (!result) return [{ type: "AppliedJobs", id: "LIST" }];

        return [
          { type: "AppliedJobs", id: "LIST" },
          ...result.jobs.ids.map((id) => ({ type: "AppliedJobs", id })),
        ];
      },
    }),
    getAppliedJobByGuid: builder.query({
      query: ({ guid, tenant }) => ({
        url: `candidates/applied-jobs/${guid}`,
        headers: {
          tenant: `${tenant}`,
        },
      }),
      providesTags: [{ type: "AppliedJob" }],
    }),
    applyJob: builder.mutation({
      query: ({ guid, data }) => ({
        url: `candidates/jobs/${guid}:apply`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Jobs", id: "LIST" },
        { type: "AppliedJobs", id: "LIST" },
        { type: "AppliedJob", id: arg?.guid },
      ],
    }),
    withdrawAppliedJob: builder.mutation({
      query: ({ guid, tenant }) => ({
        url: `candidates/applied-jobs/${guid}:withdraw`,
        headers: {
          tenant: `${tenant}`,
        },
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Jobs", id: "LIST" },
        { type: "AppliedJobs", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetJobsQuery,
  useGetJobByGuidQuery,
  useGetAppliedJobByGuidQuery,
  useGetAppliedJobsQuery,
  useApplyJobMutation,
  useWithdrawAppliedJobMutation,
} = jobSlice;
