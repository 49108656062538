import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: "/auth/register",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: [{ type: "Candidate"}],
    }),
    createProfile: builder.mutation({
      query: (credentials) => ({
        url: "/candidates",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    updateUser: builder.mutation({
      query: (credentials) => ({
        url: "/users",
        method: "PUT",
        body: { ...credentials },
      }),
    }),
    updateProfile: builder.mutation({
      query: (credentials) => ({
        url: "/candidates ",
        method: "PUT",
        body: { ...credentials },
      }),
      invalidatesTags: [{ type: "Candidate"}],
    }),
    getCandidateDetails: builder.query({
      query: () => "/candidates",
      providesTags: [{ type: "Candidate" }],
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useCreateProfileMutation,
  useUpdateUserMutation,
  useUpdateProfileMutation,
  useGetCandidateDetailsQuery,
} = authApiSlice;
