import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText,
} from "reactstrap";
import Multiselect from "multiselect-react-dropdown";
import {
  useGetCompaniesQuery,
  useGetWorkTypesQuery,
} from "../../features/staticData/staticData";
import { format, parse } from "date-fns";
import "./CandidateExperienceModal.css";
import CandidateSkill from "../CandidateSkill/CandidateSkill";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  useAddCandidateExperienceMutation,
  useRemoveCandidateExperienceMutation,
  useUpdateCandidateExperienceMutation,
} from "../../features/candidate/candidateSlice";
import { errorToast, successToast } from "../common/ToastWrapper/ToastWrapper";
import LoadingSpinner from "../common/LoadingSpinner/LoadingSpinner";

const CandidateExperienceModal = ({
  showModal,
  setShowModal,
  isUpdateMode,
  setExperienceUpdateMode,
  selectedExperience,
  setSelectedExperience,
}) => {
  const [experienceData, setExperienceData] = useState({
    title: "",
    description: "",
    workType: "",
    currentRole: false,
  });
  const [startDate, setStartDate] = useState("");
  const [companyGuid, setCompanyGuid] = useState("");
  const [endDate, setEndDate] = useState("");
  const [companies, setCompanies] = useState([]);
  const [workingTypes, setWorkingTypes] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [
    addCandidateExperience,
    {
      isLoading: addCandidateExperienceIsLoading,
      addCandidateExperienceIsSuccess,
    },
  ] = useAddCandidateExperienceMutation();

  const [
    updateCandidateExperience,
    {
      isLoading: updateCandidateExperienceIsLoading,
      updateCandidateExperienceIsSuccess,
    },
  ] = useUpdateCandidateExperienceMutation();

  const {
    data: currentCompanies,
    isLoading: currentCompaniesIsLoading,
    isFetching: currentCompaniesIsFetching,
    error: currentCompanieslsError,
  } = useGetCompaniesQuery();

  const {
    data: candidateWorkingTypes,
    isLoading: candidateWorkingTypesIsLoading,
    isFetching: candidateWorkingTypesIsFetching,
    error: candidateWorkingTypeslsError,
  } = useGetWorkTypesQuery();

  useEffect(() => {
    if (currentCompanies) {
      setCompanies(currentCompanies);
    }
    if (currentCompanieslsError) {
      return;
    }
  }, [companies, currentCompanieslsError, currentCompanies]);

  useEffect(() => {
    if (candidateWorkingTypes) {
      setWorkingTypes(candidateWorkingTypes);
    }
    if (candidateWorkingTypeslsError) {
      return;
    }
  }, [workingTypes, candidateWorkingTypeslsError, candidateWorkingTypes]);

  const toggleModal = () => setShowModal(!showModal);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setExperienceData({
      ...experienceData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleAddCompanyGuid = (selectedList, selectedItem) => {
    setCompanyGuid(selectedItem?.guid);
  };

  useEffect(() => {
    if (!showModal) {
      setExperienceData({
        title: "",
        description: "",
        workType: "",
        currentRole: false,
      });
      setCompanyGuid("");
      setSelectedSkills([]);
      setStartDate("");
      setEndDate("");
      setExperienceUpdateMode(false);
      setSelectedExperience("");
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal && isUpdateMode) {
      setExperienceData({
        title: selectedExperience?.title || "",
        description: selectedExperience?.description || "",
        workType: selectedExperience?.workType?.guid || "",
        currentRole: selectedExperience?.isCurrentlyWorkingOn || false,
      });
      setCompanyGuid(selectedExperience?.company?.guid);
      setStartDate(selectedExperience?.startDate);
      setEndDate(selectedExperience?.endDate);
    }
  }, [showModal, isUpdateMode]);

  const addCandidateExperienceOnClick = async () => {
    try {
      let response;
      const experienceObj = {
        guid: selectedExperience?.guid,
        experienceDetails: {
          title: experienceData?.title,
          description: experienceData?.description,
          companyGuid: companyGuid,
          workTypeGuid: experienceData?.workType,
          isCurrentlyWorkingOn: experienceData?.currentRole,
          startDate: startDate,
          endDate: endDate,
          skills: selectedSkills,
        },
      };
      if (isUpdateMode && selectedExperience) {
        response = await updateCandidateExperience(experienceObj).unwrap();
      } else {
        const { experienceDetails } = experienceObj;
        response = await addCandidateExperience(experienceDetails).unwrap();
      }
      if (response?.error) {
        errorToast(
          response?.error?.Message ||
            "Error while saving the Experience Details"
        );
      }
      if (isUpdateMode && selectedExperience) {
        successToast("Experience Updated Successfully");
      } else {
        successToast("Experience Added Successfully");
      }
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal isOpen={showModal} toggle={toggleModal} centered="true" size="lg">
        {addCandidateExperienceIsLoading ||
        updateCandidateExperienceIsLoading ? (
          <LoadingSpinner />
        ) : (
          ""
        )}
        <ModalHeader toggle={toggleModal}>
          {isUpdateMode ? "Update Experience" : "Add Experience"}
        </ModalHeader>
        <ModalBody className="p-4">
          <Form>
            <FormGroup>
              <Label for="title">Title</Label>
              <Input
                type="text"
                name="title"
                value={experienceData?.title}
                id="title"
                placeholder="Ex: Retail Sales Manager"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="py-2">
              <Label for="title">Description</Label>
              <textarea
                name="description"
                id="title"
                value={experienceData?.description}
                className="form-control"
                placeholder="Type description"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <label for="employmentType" className="d-block">
                Work Type
              </label>
              <select
                className="form-control"
                name="workType"
                value={experienceData?.workType}
                id="employmentType"
                onChange={handleChange}
              >
                <option value="">Select work type</option>
                {workingTypes?.map((workingType) => (
                  <option key={workingType?.id} value={workingType?.guid}>
                    {workingType.name}
                  </option>
                ))}
              </select>
            </FormGroup>
            <FormGroup className="py-2">
              <Label for="employmentType">Company Name</Label>
              <div className="col-12">
                <Multiselect
                  options={companies}
                  selectedValues={
                    selectedExperience ? [selectedExperience?.company] : ""
                  }
                  onSelect={handleAddCompanyGuid}
                  isObject={true}
                  // onRemove={handleSkillRemove}
                  displayValue="name"
                  closeOnSelect={true}
                  placeholder="Select Company"
                  avoidHighlightFirstOption="true"
                  selectionLimit={1}
                  style={{
                    chips: {
                      background: "#2F00FE",
                    },
                    searchBox: {
                      border: "none",
                      borderBottom: "1px solid #2F00FE",
                      borderRadius: "0px",
                    },
                  }}
                />
              </div>
            </FormGroup>
            <FormGroup check className="py-2">
              <Label check>
                <Input
                  type="checkbox"
                  name="currentRole"
                  checked={experienceData?.currentRole}
                  value={experienceData?.currentRole}
                  onChange={handleChange}
                />{" "}
                I am currently working in this role
              </Label>
            </FormGroup>
            <Row className="py-3">
              <CandidateSkill
                initialSelectedSkills={
                  selectedExperience?.skills ? selectedExperience?.skills : ""
                }
                setSelectedSkillGuids={setSelectedSkills}
                section={"candidate-experience"}
              />
            </Row>
            <Row className="mt-3">
              <Col md={6}>
                <FormGroup>
                  <Label for="startDate">Start Date</Label>
                  <Row>
                    <Col>
                      <DatePickerComponent
                        id="CreatedOnLessThan"
                        value={startDate}
                        format="dd-MM-yyyy"
                        placeholder="dd-mm-yyyy"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="endDate">End Date</Label>
                  <Row>
                    <Col>
                      <DatePickerComponent
                        id="CreatedOnLessThan"
                        value={endDate}
                        format="dd-MM-yyyy"
                        placeholder="dd-mm-yyyy"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                className="px-4 btn-blue"
                onClick={addCandidateExperienceOnClick}
              >
                {isUpdateMode ? "Update" : "Add"}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CandidateExperienceModal;
