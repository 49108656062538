import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")),
    candidate: JSON.parse(localStorage.getItem("candidate")),
  },
  reducers: {
    setCredentials: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setCandidate: (state, action) => {
      state.candidate = action.payload;
      localStorage.setItem("candidate", JSON.stringify(action.payload));
    },
    logOut: (state, action) => {
      state.user = null;
      state.candidate = null;
      localStorage.clear();
    },
  },
});

export const { setCredentials, logOut, setCandidate, setUser } =
  authSlice.actions;
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentCandidate = (state) => state.auth.candidate;
export default authSlice.reducer;
