import React from "react";
import toast, { Toaster } from "react-hot-toast";

const ToastWrapper = () => {
  return (
    <>
      <Toaster
        containerStyle={{
          top: "13%",
        }}
      />
    </>
  );
};

export const successToast = (msg) => {
  toast.success(msg, {
    style: {
      minWidth: "70vw",
    },
    className: "bg-success text-white",
  });
};

export const errorToast = (msg, duration) => {
  toast.error(
    msg,
    {
      style: {
        minWidth: "70vw",
      },
      className: "bg-danger text-white",
    },
    duration
  );
};

export default ToastWrapper;
