import React, { useEffect, useState, useRef } from "react";
import "./FindJobs.css";
import JobInfo from "../../components/common/JobInfo/JobInfo";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { useGetJobsQuery } from "../../features/jobs/jobSlice";
import { format } from "date-fns";
import {
  useGetJobCategoriesQuery,
  useGetWorkTypesQuery,
} from "../../features/staticData/staticData";
import { errorToast } from "../../components/common/ToastWrapper/ToastWrapper";
import DotSpinner from "../../components/common/LoadingSpinner/DotSpinner";
import closeIcon from "../../assets/icons/close.svg";
import { useLocation } from "react-router-dom";

const FindJobs = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jobId = searchParams.get("jobid");

  const [jobData, setJobData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tenant, setTenant] = useState("");
  const jobCategoryRef = useRef();
  const workTypeRef = useRef();
  const queryRef = useRef();

  const [filterObject, setFilterObject] = useState({
    categoryId: "",
    workTypeId: "",
    query: "",
  });

  const { data, isLoading, isSuccess, isError, error, isFetching } =
    useGetJobsQuery({
      page: currentPage,
      pageSize,
      ...filterObject,
    });

  useEffect(() => {
    if (data) {
      const { jobs, pagination } = data;
      const { itemsCount, page, pageSize, total, totalPages } = pagination;

      if (jobs) {
        const data = jobs.ids.map((id) => {
          return jobs.entities[id];
        });
        setJobData(data);
        setSelectedJob(jobId ? jobId : data[0]?.guid);
        setTenant(data[0]?.company?.schema);
        setTotalJobs(total);
        setCurrentPage(page);
        setTotalPages(totalPages);
      }
    }
    if (isError) {
      console.log(error);
      errorToast(error?.data?.Message || error?.data?.title);
    }
  }, [data, jobId]);
  const { data: workTypes } = useGetWorkTypesQuery();
  const { data: jobCategories } = useGetJobCategoriesQuery();

  const handleJobSelection = (guid) => {
    setSelectedJob(guid);
  };

  const handleGoForward = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleGoBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFindJobs = () => {
    setFilterObject({
      categoryId: jobCategoryRef.current.value,
      workTypeId: workTypeRef.current.value,
      query: queryRef.current.value,
    });
  };

  const clearFilters = () => {
    jobCategoryRef.current.value = "";
    workTypeRef.current.value = "";
    queryRef.current.value = "";
    setFilterObject({
      categoryId: "",
      workTypeId: "",
      query: "",
    });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-center content-container">
          <div className="col-12">
            <div className="mt-2" style={{ paddingTop: "7em" }}>
              <div className="row justify-content-between">
                <div className="col-md">
                  <input
                    type="text"
                    className="form-control"
                    style={{ padding: "0.7em" }}
                    placeholder="Explore jobs..."
                    ref={queryRef}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        handleFindJobs();
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleFindJobs();
                      }
                    }}
                  />
                </div>

                <div className="col-md-2">
                  <select
                    className="form-control"
                    style={{ padding: "0.7em" }}
                    ref={jobCategoryRef}
                    onChange={(e) => {
                      setFilterObject((prev) => ({
                        ...prev,
                        categoryId: e.target.value,
                      }));
                    }}
                  >
                    <option value={""}>Select Job Category</option>
                    {jobCategories?.map((category) => (
                      <option key={category?.guid} value={category?.id}>
                        {category?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2">
                  <select
                    className="form-control"
                    style={{ padding: "0.7em" }}
                    ref={workTypeRef}
                    onChange={(e) => {
                      setFilterObject((prev) => ({
                        ...prev,
                        workTypeId: e.target.value,
                      }));
                    }}
                  >
                    <option value={""}>Select Work Type</option>
                    {workTypes?.map((workType) => (
                      <option key={workType?.guid} value={workType?.id}>
                        {workType?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-2">
                  <div className="d-flex gap-1">
                    <button
                      className="btn btn-blue btn-basic"
                      onClick={handleFindJobs}
                      disabled={isFetching}
                    >
                      {isFetching ? <DotSpinner /> : "Find Jobs"}
                    </button>
                    {Object.values(filterObject).some(
                      (value) => value !== ""
                    ) && (
                      <button
                        className="btn btn-clear"
                        title="Clear search filters"
                        onClick={clearFilters}
                      >
                        <img src={closeIcon} alt="clear icon" width={26} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card find-jobs-container"
              style={{
                borderRadius: "1em",
                minHeight: "70vh",
                maxHeight: "auto",
              }}
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : jobData.length > 0 ? (
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-12 col-md-5">
                      <div className="list-group list-group-flush job-list">
                        <div className="pagination d-flex justify-content-between">
                          <div className="">Showing {totalJobs} results</div>
                          <div className="">
                            <span>
                              {currentPage * pageSize - pageSize + 1} -{" "}
                              {currentPage === totalPages
                                ? totalJobs
                                : currentPage * pageSize}{" "}
                              of {totalJobs}
                            </span>
                            <button
                              onClick={handleGoBack}
                              className="pagination-button"
                            >
                              <div className="svg-container">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-chevron-left"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                                  />
                                </svg>
                              </div>
                            </button>
                            <button
                              onClick={handleGoForward}
                              className="pagination-button"
                            >
                              <div className="svg-container">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-chevron-right"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                  />
                                </svg>
                              </div>
                            </button>
                          </div>
                        </div>
                        <div className="list-wrapper p-2">
                          {isLoading && <LoadingSpinner />}
                          {jobData &&
                            jobData.map((job) => (
                              <div
                                key={job.guid}
                                className={`list-group-item list-group-item-action py-3 lh-sm border-top-0 border-end-0 border-start-0 ${
                                  job.guid === selectedJob ? "selected-job" : ""
                                }`}
                                onClick={() => handleJobSelection(job?.guid)}
                              >
                                <div className="d-flex w-100 align-items-center justify-content-between">
                                  <h5 className="mb-2 active">{job?.title}</h5>
                                </div>
                                <div className="col-10 mb-1">
                                  <p className="fs-6">
                                    {job?.company?.name ||
                                    job?.company?.location
                                      ? `${job?.company?.name}, ${job?.company?.location}`
                                      : ""}
                                  </p>
                                </div>
                                <p className="d-flex align-items-center justify-content-between m-0">
                                  <div>
                                    <span className="badge text-bg-primary me-1">
                                      {job?.category?.name}
                                    </span>
                                    <span className="badge text-bg-info">
                                      {job?.workType?.name}
                                    </span>
                                  </div>

                                  <span>
                                    Deadline :
                                    <small className="badge text-bg-warning ms-2">
                                      {job?.deadline
                                        ? format(
                                            new Date(job?.deadline),
                                            "dd-MM-yyyy"
                                          )
                                        : "-"}
                                    </small>
                                  </span>
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-7">
                      <JobInfo jobGuid={selectedJob} tenant={tenant} />
                    </div>
                  </div>
                </div>
              ) : (
                <h6 className="text-center mt-5">No jobs available</h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindJobs;
