import axios from "axios";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../features/auth/authSlice";
import { errorToast } from "../components/common/ToastWrapper/ToastWrapper";

const useGetAttachment = () => {
  const user = useSelector(selectCurrentUser);
  const getFile = async (guid) => {
    try {
      const response = await axios.get(
        `${
          window.location.hostname === "localhost"
            ? process.env.REACT_APP_API_URL
            : process.env.PUBLIC_URL
        }/v1/attachments/${guid}`,
        {
          headers: {
            responseType: "arrayBuffer",
            Accept: "application/pdf",
            Authorization: `Bearer ${user?.token}`,
          },
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      errorToast("Failed to fetch the PDF document. " + error?.message);
    }
  };

  return getFile;
};

export default useGetAttachment;
