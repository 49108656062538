export function formatToCurrency(amount) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(amount);
}

export function getCandidateProfilePictureUrl(candidateGuid) {
  const baseUrl = window.location.hostname === "localhost" 
    ? process.env.REACT_APP_API_URL 
    : process.env.PUBLIC_URL;

  return `${baseUrl}/v1/candidates/${candidateGuid}/profile-picture`;
}
