import AppliedJobs from "./pages/AppliedJobs/AppliedJobs";
import CreateProfile from "./pages/CreateProfile/CreateProfile";
import FindJobs from "./pages/FindJobs/FindJobs";
import HomePage from "./pages/HomePage/HomePage";
import LandingPage from "./pages/LandingPage/LandingPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import Resources from "./pages/ResourcesPage/Resources";

const AppRoutes = [
  {
    path: "/create-profile",
    component: <CreateProfile update={false} />,
  },
  {
    path: "/update-profile",
    component: <CreateProfile update={true} />,
  },
  // {
  //   path: "/find-jobs",
  //   component: <FindJobs />,
  // },
  {
    path: "/applied-jobs",
    component: <AppliedJobs />,
  },
  {
    path: "/resources",
    component: <Resources />,
  },
  // {
  //   path: "/aboutUs",
  //   component: <LandingPage />,
  // },
];

export default AppRoutes;
