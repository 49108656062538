import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

export const staticDataSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorkTypes: builder.query({
      query: () => `jobs/working-types`,
    }),
    getSkills: builder.query({
      query: () => `skills`,
    }),
    getJobCategories: builder.query({
      query: () => `jobs/categories`,
    }),
    getCompanies: builder.query({
      query: () => `/companies`,
    }),
  }),
});

export const {
  useGetJobCategoriesQuery,
  useGetSkillsQuery,
  useGetWorkTypesQuery,
  useGetCompaniesQuery
} = staticDataSlice;
