export const appliedJobStatus = {
  WITHDRAW: "dfd62c59-29aa-419f-bbfe-acc769e851ef",
  REJECTED: "1e885a73-1092-42e4-aaf2-56fcf62948bc",
  APPLIED: "0668bd46-6b24-4afe-ab9e-b3dd142b0ca5",
  APPROVED: "9b6a4f9c-1bf2-464d-b1a4-5fe886d6e29e",
  HIRED: "7a46f7b1-cb12-46fe-9268-ae8849bc9f0a",
  PENDING: "b0470b07-06aa-4c47-8033-e760aa82d849",
};

export const interviewPipelineStageStatus = {
  COMPLETED: "630e9d39-fbe0-4647-8d2d-ae06ad9fd455",
  INCOMPLETED: "d57925b9-df1b-4ce1-9a77-88a1046ec6d4",
  SCHEDULED: "9cf39905-4324-4e21-875d-b6b590e2648b",
  PENDING: "8eb56d7d-6276-4a97-9bc1-ef6519b42637",
  REJECTED: "deb5a600-9cd9-482d-8e9b-81f1d1997d47",
};
