import React from 'react'
import { Link } from 'react-router-dom'
import "./Footer.css"

const Footer = () => {
  return (
    <div className="footer">
    <div className="footerText" id="address">
      <p>33 Boston Road South,</p>
      <p>Holbeach, Spalding,</p>
      <p>England,</p>
      <p>PE12 7LR</p>
    </div>

    <div className="footerIcons pb-5">
      <div className="iconBox">
        <Link
          className="icon"
          id="twitter"
          to="https://twitter.com/IntellTalent"
          target="_blank"
          title="Twitter"
        ></Link>
      </div>
      <div className="iconBox">
        <Link
          className="icon"
          id="linkedIn"
          to="https://www.linkedin.com/company/intellimorph-talent/"
          target="_blank"
          title="LinkedIn"
        ></Link>
      </div>
      <div className="iconBox">
        <Link className="icon" id="glassDoor" title="GlassDoor"></Link>
      </div>
    </div>
  </div>
  )
}

export default Footer